import { aiChatUouActions } from '@wix/bi-logger-forms-platform-uou/v2';
import { generateUUID } from '@wix/form-schema';
import { type Logger } from '@wix/fe-essentials/bi';
import { type aiChatUouActionsParams } from '@wix/bi-logger-forms-platform-uou/v2/types';
import { useMemo } from 'react';
import { formFieldEditAi } from '@wix/bi-logger-forms-data/v2';

export enum AssistantBiAction {
  open = 'open',
  close = 'close',
  send = 'send',
  error_message = 'error message',
}

export type CommonBiParams = Pick<
  aiChatUouActionsParams,
  'form_fill_session_id' | 'form_guid' | 'namespace'
>;

const NAMESPACE = 'wix.form_app.form';

function getFormFillingSessionId() {
  return generateUUID();
}

export function useAssistantBi({ bi, formId }: { bi: Logger; formId: string }) {
  const formFillingSessionId = useMemo(getFormFillingSessionId, []);

  const commonParams: CommonBiParams = {
    namespace: NAMESPACE,
    form_guid: formId,
    form_fill_session_id: formFillingSessionId,
  };

  return {
    formFillingSessionId,
    reportAction: (action: AssistantBiAction) => {
      bi.report(
        aiChatUouActions({
          ...commonParams,
          action,
        }),
      );
    },
    reportAiFieldEdit: (changedFieldTargets: string[]) => {
      bi.report(
        formFieldEditAi({
          ...commonParams,
          fieldTargetArray: changedFieldTargets.join(','),
        }),
      );
    },
  };
}
